@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&family=Roboto:ital@0;1&display=swap');

html {
  font-size: 12px;
}

@media only screen and (min-width: 400px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Merienda', serif;
}

svg:focus {
  outline: none;
}